
:root {
    --primary-color: #6c9e32;
  
    /* --back-ground-color: #ffffff; */
    /* --secondar-back-ground-color: #f5f5f5; */
  
    --dark-color: #000000;
    --grey-color: #ffffff;
    --light-color: #ccc;
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap");
  
  /* * {
    box-sizing: border-box;
  } */
  
  /* body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    background-color: var(--secondar-back-ground-color);
  } */
  
  .container {
    text-align: center;
  }
  
  .progress-bar-container {
    position: relative;
  }
  
  .progress-bar-container::before {
    content: "";
    position: absolute;
    background-color: var(--light-color);
    height: 10px;
    width: 100%;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
  
  #progress-bar {
    position: absolute;
    background-color: var(--primary-color);
    height: 10px;
    width: 0%;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    transition: 0.3s ease;
  }
  
  .circles-container {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    width: 400px;
  }
  
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background-color: var(--light-color);
    transition: 0.4s ease;
  }
  
  .circle h3 {
    position: absolute;
    left: 37%;
    top: -30%;
    font-size: 20px;
    color: var(--grey-color);
  }
  
  .circle.active {
    background-color: var(--primary-color);
  }
  
  .circle.active h3 {
    color: var(--dark-color);
  }

  .circle.active.done h3 {
    color: var(--grey-color);
  }
  
  .form-group {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  
  