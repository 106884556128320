.button-menu {
    -webkit-appearance: none;
	/* display:inline-block; */
	padding-top:0.875em;
	padding-right:1.75em;
	padding-bottom:15px;
	padding-left:1.75em;
	font-size:0.875em;
	border:none;
	
	cursor:pointer;
}

.button-menu {
	--primary-color: #6c9e32;
--dark-color: #000000;
--grey-color: #ffffff;
--light-color: #ccc;
font-family: 'Open Sans', 'Avenir', sans-serif;
font-style: normal;
line-height: 1.7;
list-style: none;
font-weight: bold;
box-sizing: border-box;
margin: 0;
padding-top: 0.875em;
padding-right: 1.75em;
padding-bottom: 15px;
padding-left: 1.75em;
font-size: 0.875em;
border: none;
cursor: pointer;
background: rgb(255, 255, 255);
color: rgb(0, 0, 0);
transition: background 300ms ease-out 0s;
}

.button-menu:hover {
	color:#000;
	background:#82a756;
}

.MuiMenuItem-root{
    min-height: inherit;
    font-size: 0.875em;
    font-family: 'Open Sans', 'Avenir', sans-serif;
}

.select-employee {
	color:#000;
	background:#9fcf67;
	transition: background 300ms ease-out;
}

.successMessage{
	color:#222222;
	background:#9fcf67;
	transition: background 300ms ease-in-out;
}

.errors {
	color:rgb(133, 0, 0);
	background:#f5b39e;
}

::placeholder{
	color:#ccc
}

.largeCheckbox{
	width: 20px;
    height: 20px;
}

.quantity::-webkit-inner-spin-button, 
.quantity::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
	-moz-appearance: textfield;
  }

.required label::after {
	content: "*";
	color: red;
  }
.required_field::after {
	content: "*";
	color: red;
	font-size: 1.5em;
}

input, select, textarea,  
.bIZQns{
	border:1px solid #CCC
}

input, select, textarea,.datepicker {
    text-align: left;
    color: #000000;
	font-weight: 600;
    background-color: #FFF;
    padding: 0.25em;
    border: 1px solid #CCC;
}

input.invalid {
	border-color:  red;
}

.flex-section {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	min-height: 0;
  }

.flex-col-scroll {
	overflow: auto;
	background-color: #ffffff;
 }

.invite {
	width: stretch;
}

.button-green {
	padding-left: 1.5em;
	padding-right: 1.5em;
}

.attachment {
	font-weight: 600;
	display: flex;
	align-items: center;
	width: auto;
	padding-left: 0px;
	padding-right: 20px;
}

.attachment p {
	/* flex: 1; */
	margin-bottom: 0px;
	max-width: 200px;
}