/* FONT IMPORTS */

/*@font-face {
    font-family: 'texgyreadventorbold';
    src: url('../fonts/texgyreadventor-bold-webfont.eot');
    src: url('../fonts/texgyreadventor-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/texgyreadventor-bold-webfont.woff') format('woff'),
         url('../fonts/texgyreadventor-bold-webfont.ttf') format('truetype'),
         url('../fonts/texgyreadventor-bold-webfont.svg#texgyreadventorbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'texgyreadventorregular';
    src: url('../fonts/texgyreadventor-regular-webfont.eot');
    src: url('../fonts/texgyreadventor-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/texgyreadventor-regular-webfont.woff') format('woff'),
         url('../fonts/texgyreadventor-regular-webfont.ttf') format('truetype'),
         url('../fonts/texgyreadventor-regular-webfont.svg#texgyreadventorregular') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
@font-face {
    font-family: 'Gotham HTF';
    src: url('../fonts/Gotham-Black.otf') format('opentype'),
    	 url('../fonts/Gotham-Black.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Gotham HTF';
    src: url('../fonts/Gotham-Book.otf') format('opentype'),
    	 url('../fonts/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
}

/* BROWSER RESETS */

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  margin:0;
  padding:0;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

/* ROW STYLES */

.row {
  width:100%;
  margin:0 auto;
  max-width:100%;
  padding:0;
}
/* 
.row:before, .row:after {
  content: " ";
  display: table;
} */
/* 
.row:after {
  clear:both;
} */

.gray {
	background:#f6f6f6;
	border-bottom:1px #dddddd solid;
	border-top:1px #dddddd solid;
}

.black {
	background:#222222;
	display:none;
}

.green-highlight {
	background:#9fcf67;
	border-bottom:1px #82a756 solid;
	border-top:1px #82a756 solid;
}

/* .last-row {
	
} */

/* COLUMN STYLES */

.column {
  position:relative;
  padding:0 0;
  float:left;
}

.column-center {
	float:none;
	clear:both;
	margin-left:auto;
	margin-right:auto;
}

.col-12 {
	width:100%;
}

.col-11 {
	width:91.66667%
}

.col-10 {
	width:83.33333%
}

.col-9 {
	width:75%;
}

.col-8 {
	width:66.66667%;
}

.col-7 {
	width:58.33333%;
}

.col-6{
	width:50%;
}

.col-xs-6{
	width:50%;float:left;
}

.col-5 {
	width:41.66667%;
}

.col-4 {
	width:33.33333%;
}

.col-3 {
	width:25%;
}

.col-2 {
	width:16.66667%;
}

.col-1 {
	width:8.33333%;
}

/* HEADER STYLES */

.header {
	position:relative;
}

.header-caption {
	background-color:rgba(159, 207, 103, 0.9);
	display:inline-block;
	padding:1em 1.5em;
	max-width:525px;
	float:right;
	z-index:2;
	right:0;
	top:55%;
	position:absolute;
	
}

.header-caption h2 {
	line-height:1.4;
}

.header-caption a {
	margin-bottom:0;
}

.hero-image img {
	width:100%;
}

/* BREADCRUMB STYLES */

.breadcrumbs .row {
	padding-top:0px;
	padding-bottom:0px;
}

.breadcrumbs p {
	margin: 1em 0;
}

/* BASIC ELEMENTS */

body, input, textarea {
  
  /*color:#222;*/
  color:#53565a;
  padding:0;
  margin:0;
  /*font-family:'Open Sans', 'Avenir', sans-serif;*/
  font-family:'Gotham HTF', sans-serif;
  font-weight:300;
  font-style:normal;
  line-height:1.7;
  position:relative;
  cursor:auto;
  font-size:105%;
}

input, textarea{
	background:#fff;
}

h1 {
	/*font-family:'texgyreadventorbold', "Verdana", "Lucida Grande", "Gill Sans", "Arial", "Helvetica", sans-serif;*/
	font-family:'Gotham HTF', sans-serif;
	padding-bottom:1em;
	font-size:2.25em;
	line-height:1.4;
	text-align:center;
}

h2 {
	/*font-family:'texgyreadventorregular', "Verdana", "Lucida Grande", "Gill Sans", "Arial", "Helvetica", sans-serif;*/
	font-family:'Gotham HTF', sans-serif;
	margin-bottom:1em;
	font-size:1.8em;
}

h3 {
	/*font-family:'texgyreadventorbold', "Verdana", "Lucida Grande", "Gill Sans", "Arial", "Helvetica", sans-serif;*/
	font-family:'Gotham HTF', sans-serif;
	padding-bottom:1em;
	font-size:1.3em;
}

a {
	/*font-weight:bold;*/
	color:#82a756;
	text-decoration:none;
	transition: color 300ms ease-out;
}

/*a:hover {
	color:#9fcf67;
}*/

a:hover {
    /*color: #6da100;*/
    cursor: pointer;
    text-decoration: none;
        text-decoration-color: -moz-use-text-color;
        text-decoration-line: none;
        text-decoration-style: solid;
    transition: 0.4s;
        transition-property: all;
        transition-duration: 0.4s;
        transition-timing-function: ease;
        transition-delay: 0s;
}



.green-highlight a {
	color:#222222;
	transition: color 300ms ease-out;
}

.green-highlight a:hover {
	color:#666666;
}

.green-highlight .button-black {
	margin-bottom:0;
}

p {
	margin-bottom:1em;
}

li {
	line-height:1.5;
	margin-bottom:0.6em;
	color:#82a756;
}

li span {
	color:#222222;
}

.slicknav_nav li {
	margin-bottom:0;
}

ul {
	padding-left: 1.4em;
    list-style-position: outside;
}
ol {
	padding-left: 1.4em;
    list-style-position: outside;
}
ol>li{
	color:#222;
}

/* BUTTONS */
.button {
  padding: 0.5em 0.5em;
  display: inline-block;
  font-size: 1em;
  border-radius: 0px;
  background: #fff;
  color: #981d97;
  width: 160px;
  max-width: 100%;
  text-align: center;
  border: 1px solid #981d97;
  border-color: #981d97;
}

.button-div{
	text-align: right;
	padding-bottom:15px
}
.button-black, .button-green, .button-ghost, .button-gray {
    -webkit-appearance: none;
	display:inline-block;
	padding-top:0.875em;
	padding-right:1.75em;
	padding-bottom:15px;
	padding-left:1.75em;
	font-size:0.875em;
	border:none;
	
	cursor:pointer;
}

.button-black {
	color:#ffffff !important;
	background:#222;
	transition: background 300ms ease-out;
}

.button-black:hover {
	background:#666;
	color:#fff;
}

.button.dark:hover {
    background-color: #878a8e;
}

.button.dark {
    background-color: #616467;
    border-color: #fff;
    color: #fff;
	width:250px;
}


.button-green {
	color:#000;
	background:#9fcf67;
	transition: background 300ms ease-out;
}

.button-green:hover {
	color:#000;
	background:#82a756;
}

.button-gray {
	color:#000;
	background:#dddddd;
	transition: background 300ms ease-out;
}

.button-gray:hover {
	color:#000;
	background:#878a8e;
}

.button-ghost {
	color:#222;
	border:2px solid #222;
	background:transparent;
	transition: all 300ms ease-out;
}

.button-ghost:hover {
	background:#222;
	color:#fff;
}

/* TABLE STYLES */

table thead tr th, table tfoot tr th, table tfoot tr td, table tbody tr th, table tbody tr td, table tr td {
    display: table-cell;
    line-height: 1em;
}

table {
	background: #ffffff;
    table-layout: auto;
    width:100%;
}

table thead {
    background: #9fcf67;
    /*font-family: 'texgyreadventorbold', "Verdana", "Lucida Grande", "Gill Sans", "Arial", "Helvetica", sans-serif;*/
    font-family: 'Gotham HTF', sans-serif;
}

table thead tr th, table thead tr td {
    color: #222222;
    font-size: 0.85em;
    padding: 1em 0.5em;
}

table tr th, table tr td {
    color: #222222;
    font-size: 0.85em;
    padding: 1em 0.5em;
    text-align: center;
}

table tr.even, table tr.alt, table tr:nth-of-type(even) {
    background: #f6f6f6;
}

.gsc-search-box table {
	background: #222222;
}
/* CIRCLE ICONS */

.icon {
	width:125px;
	height:125px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:1em;
}

.icon img {
	width:100%;
}

.icon-fa {
	width:100px;
	height:100px;
	border-radius:50%;
	background:#9fcf67;
	text-align:center;
	margin:0 auto 8px;
	padding-top:15px;
	color:#000000;
	font-size:65px;
	line-height:50px;
}


/* INVESTMENT LOGOS */

.logo {
	width:300px;
	height:80px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:1em;
}

.logo img {
	width:100%;
}

/* CHARTS */

.chart {
	max-width:315px;
	height:auto;
	margin:1.5em auto 1em;
}

.chart img {
	width:100%;
}


/* MAIN NAV STYLES */

/*.main-nav {
	background:#f6f6f6;
	border-bottom:1px #dddddd solid;
}*/

.main-nav .row {
	padding:16px 0;
}

.main-nav img {
	max-width:192px;
}

/*.hero-buttons {margin-bottom:30px;}*/
.hero-buttons a { width:260px; max-width:100%;}
.hero-buttons-margin {margin-bottom:24px;}

.black-current ul {list-style:none; display:inline-block; z-index:999;}
.black-current li {display:inline-block; position:relative; margin-bottom:0;}
.black-current ul li a {line-height:1.7;}
.black-current li ul {display:none; position:absolute; width:180px; left:0; top:100%; padding:0;}
.black-current li li a {display:block; margin:0;}
.black-current li li {text-align:left; float:none; display:block;}
.black-current li:hover > ul {display:block;}
.black-current li:hover > .button-black.top {padding-bottom:21px; margin-bottom:0;}


/* SECONDARY NAV */
.info-nav ul {margin:0; list-style:none; float:right; display:block; z-index:999;}
.info-nav li {display:inline-blcok; position:relative;}
.info-nav ul li {display:block; float:left;}
.info-nav a {margin-left:1.9em; color:#999999;}
.info-nav a:hover {color:#555555;}
.info-nav li ul {display:none; position:absolute; width:300px; left:0; top:100%; padding-top:15px; background:#f6f6f6; padding-left:0; border-bottom:1px #dddddd solid;}
.info-nav li li a {display:block;}
.info-nav li li {text-align:left; float:none;}
.info-nav li:hover > ul {display:block;}


	
.slicknav_menu {
	display:none;
}

.green-current, .black-current {
	display:inline-block;
}

.green-current a.current span {
	border-bottom:1px solid #333333;
	padding-bottom:0.2em;
}

.black-current a.current span {
	border-bottom:1px solid #ffffff;
	margin-bottom:0.2em;
}

/* FOOTER STYLES */

.footer {
	background:#222;
	color:#777;
}

.footer a {
	color:#999;
}

.footer a:hover {
	color:#ccc;
}

.footer-nav ul, .social-media ul {
	padding:0;
	list-style:none;
	overflow:hidden;
	display:table;
}

.footer-nav ul>li, .social-media ul>li {
	list-style:none;
	float:left;
	display:block;
	margin-right:2.5em;
}

.social-media ul>li {
	margin:0 1.25em 1em 1.25em;
}

.subscribe {
	text-align:center;
	max-width:90%;
}

.footer-logo {
	width:175px;
	float:right;
}

.footer-logo img, .header-logo img {
	width:100%;
	max-width: 100px;
}

.footer .fine-print {
	line-height:1.2;
}

/* CONTACT US FORM */

/* LMNO */

.css-label-white{
	width:100%;
}

#contact {
	background:transparent;
	border:none;
	padding:0;
	margin:0;
}

#contact-label {
	font-size:0.9em;
}

.bold-label {
	font-weight:bold;
}

input.button-green {
	margin-top:0.8em;
}

/*
 *
 *	LMNO DARK THEME
 * 
 * */

.dark-theme{
	background:transparent;
	color:#ffffff;
}

/*
 *
 * SUBSCRIBE FORM LMNO 
 * 
 * */
.subscribe-form{
	padding:0 !important;
	font-size:14px;
}

.subscribe-form h2{
	text-transform:uppercase;
	font-size:21px;
	line-height:1.2em;
}
.subscribe-form p{
	margin-bottom:0;
} 

.subscribe-form label{
	margin-bottom:5px !important;
	margin-top:8px !important;
}

.subscribe-form input.button{
	float:right;
	margin-top:10px;
}

/* FORM STYLES */

@media screen and (max-width: 480px){
  #business_subscribe .contact-name .col-3{
      padding:0;
      
  }
  
  #business_subscribe .contact-name .col-3 label{
  	margin-bottom:40px !important;
  }
}


label {
	display:block;
	/*margin-bottom:0.6em;*/
	margin-top:0.8em;
}

input.button-green {
	font-weight:bold !important;
}

input[type="email"], input[type="name"], input[type="file"], textarea {
	text-align: left;
	color: #8a8a8a;
	padding:0.25em;
	border:1px solid #ccc;
	width:100%;
}

textarea {
	min-height:200px;
	line-height:1.4;
	resize: none;
	width:100% !important;
}

select::-ms-expand {
    display:none;
}

select {
	border-radius:0px;
	-webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: 0.5em;
    padding-right: 1.5em;
	font-size:1em;
	width:100%;
	height:2.5em;
	border: 1px solid #ccc;
}

option {
	margin:1.5em 0;
}

#subscribe {
    position: relative;
}

#subscribe input[type="email"] {
	background:#f6f6f6 !important;
}

input[type=checkbox].css-checkbox, input[type=radio].css-checkbox {
	position:absolute; 
	z-index:-1000; 
	left:-1000px; 
	overflow: hidden; 
	clip: rect(0 0 0 0); 
	height:1px; width:1px; 
	margin:-1px; 
	padding:0; 
	border:0;
}

input[type=checkbox].css-checkbox + label.css-label, input[type=checkbox].css-checkbox + label.css-label-white, input[type=radio].css-checkbox + label.css-label-radio, input[type=radio].css-checkbox + label.css-label-radio-white {
	padding-left:25px;
	height:20px; 
	display:inline-block;
	line-height:20px;
	background-repeat:no-repeat;
	background-position: 0 0;
	vertical-align:middle;
	cursor:pointer;
}

input[type=checkbox].css-checkbox:checked + label.css-label, input[type=checkbox].css-checkbox:checked + label.css-label-white, input[type=radio].css-checkbox:checked + label.css-label-radio, input[type=radio].css-checkbox:checked + label.css-label-radio-white  {
	background-position: 0 -20px;
}

label.css-label, label.css-label-white, label.css-label-radio, label.css-label-radio-white  {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom:1.5em;
}
/* 
label.css-label {
	background-image:url('../images/checkbox.png');
}

label.css-label-white {
	background-image:url('../images/checkbox-white.png');
}

label.css-label-radio {
	background-image:url('../images/radio.png');
}

label.css-label-radio-white {
	background-image:url('../images/radio-white.png');
} */

#email-text label {
	margin-right:0.5em;
}

#list-select label {
	margin-right:1em;
}

.black input[name="searchTerm"], .slicknav_nav input[name="searchTerm"] {
	text-align: left;
	color: #ffffff;
	border:none;
	width:100%;
	min-width:250px;
	background:transparent;
	padding:0.5em 0;
}

.black input[name="searchTerm"] {
	font-size:1.6em;
}

.slicknav_nav input[name="searchTerm"] {
	font-size:0.9em;
}

.slicknav_nav ::-webkit-input-placeholder {
   color:#cccccc;
}

.slicknav_nav :-moz-placeholder { /* Firefox 18- */
   color:#cccccc;  
}

.slicknav_nav ::-moz-placeholder {  /* Firefox 19+ */
   color:#cccccc; 
}

.slicknav_nav :-ms-input-placeholder {  
  color:#cccccc;  
}

small.success {
    display: block;
    font-size: 0.75em;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 0.9em;
    margin-top: -1px;
    padding: 0.4em 0.5em 0.5em;
    background: #82a756;
    color: #FFFFFF;
} 	

/* FORM ERROR STYLES */

label.error {
	color:#f04124;
}

span.error, small.error {
    display: block;
    font-size: 0.75em;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 0.9em;
    margin-top: -1px;
    padding: 0.4em 0.5em 0.5em;
    background: #f04124;
    color: #FFFFFF;
} 	

/* QUICK LINKS STYLES */

.quick-links-cont {
	width:100%;
	margin:3em auto 0;
	
}


.quick-links p a {
	margin-left:3%;
	color:#000000;
}

.quick-links p a:hover {
	margin-left:3%;
	color:#ffffff;
}




.quick-links {
	padding:1em;
	background:#9fcf67;
	color:#ffffff;
}

.quick-links p {
	margin-bottom:0;
}


/* UNIVERSAL STYLES */

.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    z-index: 5;
    top:0;
    left:0;
}

.fine-print {
	font-size:0.8em;
}

.text-center {
	text-align:center !important;
}

.text-right {
	text-align:right !important;
}

.text-left {
	text-align:left !important;
}

.clearfix:after {
	content: "."; 
	visibility: hidden; 
	display: block; 
	height: 0; 
	clear: both;
}

/* VIDEO EMBED STYLES */

#video {
	width:100%;
}

#videohalf {
	width:100%;
}

/* ACCORDION ELEMENTS */ 

.accordion-toggle {
  	cursor:pointer;
  	padding:0.75em 2.5em 0.75em 1.6em;
  	border:0.1em #dddddd solid;
  	background-color:#f6f6f6;
  	margin-top:0.5em;
}

.gray .accordion-toggle {
	background-color:#ffffff;
}

.accordion-toggle:hover {
	background-color:#e8e8e8;
}

.accordion-toggle .fa {
	color:#bbbbbb;
	position:absolute;
	right:1em;
	top:38%;
}

.accordion-content {display:none;}
  
.accordion-content p {
	margin-top:1em;
}
  
.accordion-content.default {display:block;}

/* BLOG FEED STYLES */

.rssincl-itemdate {
	font-size:0.8em;
}

.rssincl-itemtitle {
	/*font-family: 'texgyreadventorbold', "Verdana", "Lucida Grande", "Gill Sans", "Arial", "Helvetica", sans-serif;*/
	font-family: 'Gotham HTF', sans-serif;
    font-size: 1.3em;
    margin-bottom:0;
}


/* NEWS FEED STYLES */

.news-feed h3 {
	padding-bottom:0;
	overflow: auto;
}  

.news-feed {
		overflow: auto;
}  



.tim-col-3 {width:100%; text-align:center;}
.tim-col-9 {width:100%; text-align:center;}

.nav-signin {padding:4px 10px; font-size:12px; border:1px solid #9fcf67; color:#ffffff; width:100%;}
.nav-phone {color:#ffffff; font-size:16px; position:absolute; top:12px; left:10px; text-align:left; padding:0;}
.nav-phone a {color: #ffffff;}
.nav-phone a:hover {color: #f2f2f2;}




/* MEDIA QUERIES */

@media screen and (max-width:768px) {
	
	body, input, textarea {
	  font-size:100%;
	}

	.column {
		float:none;
		padding:0.8em;
	}
	
	.main-nav .column {
		padding:0;
	}
	
	.col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
		width:100%;
	}
	
	.row {
		padding-top:1em;
		padding-bottom:1em;
	}
	
	.accordion-toggle {
  		padding:0.75em 2.5em 0.75em 1em;
  	}
	
	.footer-logo {
		float:none;
		margin:0 auto;
	}
	
	.footer-nav ul {
		width:100%;
		margin-bottom:1.5em;
	}
	
	.footer-nav ul>li {
		float:none;
		text-align:center;
		margin-right:0;
		margin-bottom:1em;
	}
	
	.social-media ul>li {
		width:50%;
		margin:0;
		padding:1em 0;
	}
	
	.footer .fine-print {
		text-align:center;
	}
	
	.footer-logo img {
		margin-bottom:1.5em;
	}
	
	h1 {
		font-size:1.7em;
	}
	
	h2 {
		font-size:1.4em;
		margin-bottom:0;
	}
	
	.header h2 {
		margin-bottom:0.5em;
	}
	
	h3 {
		font-size:1.2em;
	}
	
	.icon {
		width:100px;
		height:100px;
	}
	
	#contact {
		margin:0;
	}
	
	
	
}

@media screen and (min-width:768px) {
	
	table {
		margin:1em 0;
	}
	
	/* .b-img {background:url(../images/beneficiaries-img1.jpg) left center no-repeat; background-size:33.3%;}
	.d-img {background:url(../images/deathbenefits-img1.jpg) left center no-repeat; background-size:33.3%;} */
	
	
	
}

@media screen and (max-width:980px) {
	
	.header-caption {
		display:inline-block;
		width:100%;
		position:static;
	}
	
	
}

@media screen and (min-width:81px) {
	
	.hide-desktop {
		display:none;
	}
	
	.nav-signin {padding:4px 10px; margin-left:105px; font-size:12px; border:1px solid #9fcf67; color:#ffffff; width:auto;}
	.nav-phone {padding:4px 1.6em; color:#ffffff; font-size:16px; position:relative; top:auto; left:auto;}
	
}

@media screen and (max-width:980px) {
	
	.hide-mobile {
		display:none;
	}
	
	/* MOBILE MENU */
	
	.js #menu {
		display:none;
	}
	
	.js .slicknav_menu {
		display:block;
	}
	
	.col-8 {width:100%;}
	.b-img, .d-img {background:none;}
	
	.nav-phone {padding:0!important;}
	
}

@media screen and (min-width:769px) and (max-width:980px) {
	
	.col-1, .col-2 {
		width:50%;
	}
	
	.col-3, .col-4 {
		width:100%;
		padding-bottom:0.8em;
	}
	
	
	
}

@media screen and (max-width:1210px){
	.info-nav a {
		margin-left:1.1em;
		
	}
	
	.info-nav a:hover {
		margin-left:1.1em;
	}
	.nav-phone {padding:4px 3.2em;}
	
}

@media screen and (max-width:1210px){
	.info-nav a {
		margin-left:1.1em;
		
	}
	
	.info-nav a:hover {
		margin-left:1.1em;
	}
	.nav-phone {padding:4px 3.2em;}
	
}

@media screen and (min-width:1211px){
	.nav-phone {padding:4px 1.6em;}
}



@media screen and (max-width:1110px){
	.info-nav a {
		margin-left: 0.9em;
		font-size: 0.9em;
	}
	
	.info-nav a:hover {
		margin-left:0.9em;
	}
	
	
}



@media only screen and (min-width: 120.063em) {

	#video {
		height: 542px;
	}

}


@media only screen and (max-width: 120.063em) and (min-width: 90.063em) {

	#video {
		height: 542px;
	}

}

@media only screen and (max-width: 90.063em) and (min-width: 64.063em) {

	#video {
		height: 480px;
	}

}

@media only screen and (max-width: 64.063em) and (min-width: 38.75em) {
	
	#video {
		height: 380px;
	}
	
}

@media only screen and (max-width: 38.75em) {

	#video {
		height: 250px;
	}

}
@media only screen and (min-width: 120.063em) {

	#videohalf {
		height: 271px;
	}

}


@media only screen and (max-width: 120.063em) and (min-width: 90.063em) {

	#videohalf {
		height: 271px;
	}

}

@media only screen and (max-width: 90.063em) and (min-width: 64.063em) {

	#videohalf {
		height: 240px;
	}

}

@media only screen and (max-width: 64.063em) and (min-width: 38.75em) {
	
	#videohalf {
		height: 190px;
	}
	
}

@media only screen and (max-width: 38.75em) {

	#video {
		height: 250px;
	}

}

/* STYLE OVERWRITES */

.no-padding {
	padding:0;
}

.no-padding-top {
	padding-top:0;
}

.no-padding-bottom {
	padding-bottom:0;
}

/*Timeline */
 .timeline {
		    width:100%;
		    float:left;
		    
		}
	    
	    .timeline-img {
		    width:100%;
		    float:left;
		    padding:0 1em;
	    }
	    
	    .timeline-img img {width:100%;}
	    
	    .timeline-copy {
		    width:100%;
		    margin-bottom:20px;
		    float:left;
		    padding:0 1em;
	    }
	    
	    .timeline-copy h2 {
		    margin:0;
		    padding:0;
	    }
	    
	    .timeline-celebrate {
		    background:#9fcf67;
		    padding:20px;
		    font-size:2.5em;
		    line-height:1.2em;
		    font-weight:300;
		    color:#ffffff;
		    text-align:left;
		    width:100%;
		    clear:both;
	    }
	    
	    .white {
		    background:#ffffff;
		    padding:20px 0;
		}
		
		.desktop {display:none;}
		.noDesktop {display:block;}
	    
	        
	    @media only screen and (min-width:768px) {
		   
		   .timeline {width:100%;}
		   
		   .timeline-img {
			    width:25%;
			    margin-bottom:20px;
		    }
		    
		    .timeline-copy {
			    width:75%;
		    }
		    
		    .timeline-celebrate {
			    text-align:right;
			    font-size:4em;
		    }
		    .timcard {position:absolute; top:-26px; transform:rotate(-3deg); width:250px; height:157px;}
		    .tim-col-3 {width:40%; text-align:left;}
		    .tim-col-9 {width:60%;}
	    }
	    
	    @media only screen and (min-width:1024px) {
		    
		    .desktop {display:block;}
		    .noDesktop {display:none;}
		    
		    .timeline {width:16.6%;}
		   
			.timeline-img {
			    width:100%;
			    margin-bottom:20px;
		    }
		    
		    .timeline-copy {
			    width:100%;
		    }
		    
		    .timcard {top:-60px; width:250px; height:157px;}
		    .tim-col-3 {width:25%;}
		    .tim-col-9 {width:75%;}
		    
		   
		    
	    }

/* Business contact form */
.business-form {
	/*font-family: 'texgyreadventorregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;*/
	font-family: 'Gotham HTF', sans-serif;
	font-size:15px;
}

.business-form .form-field {
  padding-bottom: 0.1rem;
}

.business-form .form-field input {
  width: 90%;
  max-width: 400px;
  padding: 0.5rem 0.25rem 0.6rem;
  text-indent: 0.25rem;
  border: 1px solid #93c954;
  border-radius: 6px;
}

.business-form .form-field .opt-group {
  max-width: 400px;
  margin: 0 auto;
}

.business-form .form-field .opt {
  position: relative;
  z-index:1;
}

.business-form .form-field .opt .bg {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: background 250ms ease-out;
  transition: background 250ms ease-out;
  border-radius: 6px;
  background: rgba(34, 34, 34, 0.075);
}

.business-form .form-field .opt input {
  line-height: 1.5rem;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  opacity: 0.01;
}

.business-form .form-field .opt input:checked ~ label {
  color: #6c9e32;
}

.business-form label {margin-bottom:0.2em;}

.business-form .form-field .opt input:checked ~ .bg {
  background: #fff;
}

.business-form .form-field .opt .label {
  line-height: 1rem;
  width: 100%;
  padding: 0rem 1rem 1rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 250ms ease-out;
  transition: color 250ms ease-out;
  vertical-align: middle;
  margin-bottom:0.2em;
}

.business-form .btn-submit {
  padding: 0.6rem 3.75rem 0.7rem;
  cursor: pointer;
  -webkit-transition: background 250ms ease-out;
  transition: background 250ms ease-out;
  color: #fff;
  border-radius: 6px;
  background: #79b138;
}

.business-form .btn-submit:hover {
  background: #8bc548;
}

.inline-block {display:inline-block !important;}

.row.opt-group {padding:0 0 0.8em;}

.opt-group .col-6 {float:left; padding-left:15px; padding-right:15px;}

.text-white {color:#ffffff;}

.business-form-head {font-size:1.8rem;}


.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 15px;
  line-height: 1.42857;
  border-radius: 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



.btn {
  /*font-family: "texgyreadventorbold", "Helvetica Neue", Helvetica, Arial, sans-serif;*/
  font-family: 'Gotham HTF', sans-serif;
  border: none;
}

@media only screen and (min-width:728px) {
	.business-form {
		font-size:18px;
	}
	
	.btn {
		font-size:18px;
	}
}