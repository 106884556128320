/*
Author: Alex Fischer
Date: Nov. 9, 2015

This file was added to style the online contribution form like the Contact Us form.
It repeats some of the styles set up in grid.css, but with more generic selectors. 

It also adds some general component classes.

So far, this stylesheet is only included on the contribute.php page and member
portal pages
*/

table {
    border-collapse: collapse;
}

.border-form {
    background:#f6f6f6;
    border:1px solid #dddddd;
    padding:2em 2em 2em 2em;
    margin:1.6em 0 2.2em;
}
.border-box {
    background-color: #F6F6F6;
    border: 1px solid #DDD;
    padding: 2em;
}
.border-box-tight {
    padding: 1em;
}
input, select, textarea {
    text-align: left;
    color: #8A8A8A;
    background-color: #FFF;
    padding: 0.25em;
    border: 1px solid #CCC;
}
.wide-input {
    width: 100%;
}

/*
    Give first child of form errors top margin.
    This means we can always print the container, and if it's empty
    no margin is added.
*/
.form-errors :first-child {
  margin-top: 2em !important;
}

.right-left td {
    text-align: left;
    padding-right: 4em;
}
.right-left td:first-child {
    text-align: right;
    padding-left: 4em;
}

.button-green {
    /*
        These styles are already applied to input.button-green by the other site styles.
        They are duplicated here so that they apply to button.button-green
    */
    font-family: inherit;
    font-weight: bold;
}

.page-error {
    margin: 1em 0;
    padding: 0.5em;
    background-color: #f04124;
    color: white;
    font-style: italic;
}

.check-mark:after {
    content: '\002714';
}

.loginbutton {
    text-align: right;
}